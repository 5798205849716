import clsx from 'clsx';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { getTranslateRoutes } from '../../../../lib/i18n/getTranslateRoutes';
import { useSearchLocationContext } from '../SearchLocation/SearchLocation.Provider';

import styles from './LeadPopUpButton.module.css';

interface ILeadPopUpButtonProps {
  translationKey?: string;
  className?: string;
}

export default function SalesButton({
  translationKey = 'sales',
  className
}: ILeadPopUpButtonProps) {
  const { district } = useSearchLocationContext();
  const { t } = useTranslation(['common']);
  const router = useRouter();
  const city = district?.title?.toLowerCase() ?? 'barcelona';

  const handleClick = () => {
    router.push({
      pathname: `${getTranslateRoutes(router.locale, 'sales')}/${city}`,
      query: {}
    });
  };

  return (
    <button className={clsx(styles.button, className)} onClick={handleClick}>
      <span>{t(translationKey)}</span>
    </button>
  );
}
