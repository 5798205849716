import useJoinHouzitStore from '@/stores/joinHouzitStore';
import { motion } from 'framer-motion';
import JoinHouzitForm from './JoinHouzitForm';
import { useTranslation } from 'next-i18next';

export default function JoinHouzitDialog() {
  const { t } = useTranslation('common');
  const setIsDialogOpen = useJoinHouzitStore(state => state.setIsDialogOpen);

  return (
    <motion.div
      className="tw-dialog-open tw-fixed tw-isolate tw-z-[150] tw-inset-0 tw-bg-black/20 tw-cursor-pointer tw-grid tw-place-items-end sm:tw-place-items-center"
      onClick={() => setIsDialogOpen(false)}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <motion.div
        className="tw-relative tw-rounded-t-lg sm:tw-rounded-lg tw-bg-gradient-to-b tw-from-white tw-to-[var(--hz-color-light-tint)] tw-h-[calc(100vh-90px)] tw-w-[min(100vw,650px)] tw-cursor-auto md:tw-w-[550px] md:tw-h-[clamp(70vh,600px,95vh)]"
        onClick={e => e.stopPropagation()}
        initial={{ opacity: 0, y: 200 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 200 }}
      >
        <div className="tw-px-5 tw-pt-12 tw-pb-16 tw-overflow-auto tw-h-full md:tw-py-12">
          <h3 className="tw-text-center tw-mb-5 tw-text-defaultColor tw-font-bold tw-text-title">
            {t('join')}
          </h3>
          <div className="tw-m-auto tw-max-w-[400px]">
            <JoinHouzitForm />
          </div>
        </div>
        <button
          className={
            "tw-bg-[url('/assets/icons/x.svg')] tw-absolute tw-right-5 tw-top-5 tw-inline-block tw-size-[24px]"
          }
          onClick={() => setIsDialogOpen(false)}
        />
      </motion.div>
    </motion.div>
  );
}
