import clsx from 'clsx';
import { useTranslation } from 'next-i18next';
import JoinHouzitDialog from './JoinHouzitDialog';
import { AnimatePresence } from 'framer-motion';
import useJoinHouzitStore from '@/stores/joinHouzitStore';

interface JoinHouzitDialogTriggerProps {
  className?: string;
}

export default function JoinHouzitDialogTrigger({
  className
}: JoinHouzitDialogTriggerProps) {
  const { t } = useTranslation(['common']);
  const isDialogOpen = useJoinHouzitStore(state => state.isDialogOpen);
  const setIsDialogOpen = useJoinHouzitStore(state => state.setIsDialogOpen);

  return (
    <>
      <button
        type="button"
        className={clsx(className, '')}
        onClick={() => setIsDialogOpen(true)}
      >
        {t('join')}
      </button>
      <AnimatePresence>{isDialogOpen && <JoinHouzitDialog />}</AnimatePresence>
    </>
  );
}
