import { useMutation } from 'react-query';
import { agentService } from '../../services';
import { IJoinHouzitInputs } from 'shared/components/common/JoinHouzit/JoinHouzitForm';

export const useJoinHouzitMutation = () => {
  return useMutation(
    ({ data }: { data: IJoinHouzitInputs }) => {
      const body = {
        ...data
      };
      return agentService.registerInterest(body);
    },
    {
      onError: () => {
        return;
      },
      onSuccess: async response => {
        return;
      }
    }
  );
};
