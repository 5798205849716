import { FC, useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import clsx from 'clsx';
import Link from 'next/link';
import LeadPopUpButton from '../../common/LeadPopUpButton/LeadPopUpButton';
import SalesButton from '../../common/SalesButton/SalesButton';
import LogoSVG from '../../../../public/assets/icons/Houzit-Logo_home.svg';
import { LanguageSelector } from '../../common/LanguageSelector/LanguageSelector';
import { HzToolbarMenuButton } from './HzToolbar.MenuButton';
import JoinHouzitDialogTrigger from '../../common/JoinHouzit/JoinHouzitDialogTrigger';

import styles from './HzToolbar.module.css';

export interface HzToolbarProps {
  color?: string;
  sticky?: boolean;
  className?: string | null;
  withLinks?: boolean;
  showMobileLangSelector?: boolean;
}

export const HzToolbar: FC<HzToolbarProps> = ({
  color,
  sticky = false,
  withLinks = true,
  className = null,
  showMobileLangSelector = false
}: HzToolbarProps) => {
  const { t } = useTranslation(['common']);

  const colorClass = useMemo(() => {
    switch (color) {
      case 'transparent':
        return 'tw-bg-transparent';
      case 'primary':
      default:
        return 'tw-bg-primary';
    }
  }, [color]);

  return (
    <header
      className={clsx(
        styles.toolbar,
        colorClass,
        sticky && 'tw-sticky tw-top-0',
        className
      )}
    >
      <div className={styles.toolbarContainer}>
        <Link href="/">
          <LogoSVG className={styles.logo} />
        </Link>
        <div className={styles.headerButtons}>
          {withLinks && (
            <>
              <SalesButton className={styles.headerMenuItem} />
              <LeadPopUpButton className={styles.headerMenuItem} />
              <JoinHouzitDialogTrigger className={styles.headerMenuItem} />
            </>
          )}
          <LanguageSelector showOnMobile={showMobileLangSelector} />
          <HzToolbarMenuButton />
        </div>
      </div>
    </header>
  );
};

export default HzToolbar;
