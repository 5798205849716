import { useSidebarContext } from '../../common/Sidebar/Sidebar.Provider';

import MenuIconSVG from '../../../../public/assets/icons/menu-icon.svg';

import styles from './HzToolbar.module.css';

export const HzToolbarMenuButton = () => {
  const { toggle } = useSidebarContext();

  return (
    <button onClick={toggle} className={styles.sidebarBtn}>
      <MenuIconSVG />
    </button>
  );
};
