import { useTranslation } from 'next-i18next';
import clsx from 'clsx';
import { useSidebarContext } from '../../common/Sidebar/Sidebar.Provider';
import React from 'react';
import { IListing } from '../../../models/listing/listing.model';
import { InstantAgentRequest } from '../../../services/lead/lead.service';
import Link from 'next/link';
import LeadPopUpButton from '../../common/LeadPopUpButton/LeadPopUpButton';

import LogoSVG from '../../../../public/assets/icons/Houzit-Logo_home.svg';
import FacebookIconSVG from '../../../../public/assets/images/social-facebook.svg';
import InstagramIconSVG from '../../../../public/assets/images/social-instagram.svg';
import WhatsappIconSVG from '../../../../public/assets/images/social-whatsapp.svg';

import styles from './HzFooter.module.css';

export interface IInstantAgentButtonProps {
  listing?: IListing;
  className?: string;
  onShowInstantAgentDialog?: () => any;
  mutationPayload?: Partial<InstantAgentRequest>;
}

export const HzFooter: React.FC<IInstantAgentButtonProps> = ({
  className = ''
}) => {
  const { t } = useTranslation(['common']);
  const { toggle } = useSidebarContext();
  const currentYear = new Date().getFullYear();

  return (
    <footer className={clsx(styles.footer, className)}>
      <div
        className={clsx(
          styles.main,
          'tw-grid tw-grid-cols-2 md:tw-grid-cols-3 tw-items-center'
        )}
      >
        <div className="tw-flex tw-flex-col tw-items-start tw-justify-center">
          <LogoSVG />
          <div className="tw-flex tw-gap-5 tw-mt-5">
            <Link
              href={'https://www.facebook.com/houzitheroes'}
              target="_blank"
              rel="nofollow"
            >
              <FacebookIconSVG />
            </Link>

            <Link
              href={'https://www.instagram.com/houzit_spain/'}
              target="_blank"
              rel="nofollow"
            >
              <InstagramIconSVG />
            </Link>

            <Link
              href={'https://wa.me/34695743755'}
              target="_blank"
              rel="nofollow"
            >
              <WhatsappIconSVG />
            </Link>
          </div>
        </div>

        <div className={styles.links}>
          <div className={styles.columnLeft}>
            <LeadPopUpButton className={styles.footerButton} />
            <Link
              href={t('terms-conditions-link')}
              locale={false}
              target="_blank"
              className={styles.footerLink}
              rel="noopener noreferrer"
            >
              {t('terms-and-conditions')}
            </Link>
            <Link
              href={t('privacy-policy-link')}
              locale={false}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('policy')}
            </Link>
            <button
              className={clsx(styles.moreLinks, styles.footerButton)}
              onClick={() => toggle()}
            >
              {t('more-links')}
            </button>
          </div>
          <div>
            <div className={styles.columnRight}>
              {/* <Link href={'/values'}>
                    <a>{t('stand-for-title')}</a>
                  </Link> */}
              <LeadPopUpButton className={styles.footerButton} />
            </div>
          </div>
          {/* TODO add link when the page will exist */}
          {/* <ion-col>
                <div className={styles.columnMiddleLeft}>
                  <a>{t('property-news')}</a>
                </div>
              </ion-col> */}
          <div>
            <div className={styles.columnMiddleRight}>
              <Link
                href={t('terms-conditions-link')}
                locale={false}
                target="_blank"
                className={styles.footerLink}
                rel="noopener noreferrer"
              >
                {t('terms-and-conditions')}
              </Link>
              <Link
                href={t('privacy-policy-link')}
                locale={false}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('policy')}
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.copyright}>
        <small>© {currentYear} Houzit</small>
      </div>
    </footer>
  );
};

export default HzFooter;
