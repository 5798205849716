import { debounce } from 'lodash';
import { useTranslation } from 'next-i18next';
import { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useJoinHouzitMutation } from 'shared/hooks/mutations/useJoinHouzitMutation';
import {
  Button,
  InputContainer,
  PhoneInput,
  PrivacyPolicyCheckbox,
  TextArea,
  TextInput
} from '../Input';
import clsx from 'clsx';
import useJoinHouzitStore from '@/stores/joinHouzitStore';

export type IJoinHouzitInputs = {
  name: string;
  surname: string;
  phoneCode?: string;
  phone: string;
  email: string;
  message: string;
  language?: string;
  terms?: boolean;
};

export default function JoinHouzitForm() {
  const { t } = useTranslation('common');
  const setIsDialogOpen = useJoinHouzitStore(state => state.setIsDialogOpen);
  const formMethods = useForm<IJoinHouzitInputs>({
    defaultValues: {
      phoneCode: '34',
      terms: false
    }
  });
  const {
    handleSubmit,
    formState: { isSubmitting }
  } = formMethods;
  const {
    mutateAsync,
    isLoading: isSending,
    isSuccess,
    isError
  } = useJoinHouzitMutation();
  const handleSubmit_ = async (data: IJoinHouzitInputs) => {
    data.phone = data.phoneCode + data.phone;
    data.phone = data.phone.replace(/\s/g, '');

    delete data.terms;
    delete data.phoneCode;

    try {
      await mutateAsync({ data });
    } catch (e) {
      console.log(e);
    }
  };
  const debouncedHandleSubmit = useMemo(() => debounce(handleSubmit_, 500), []);

  useEffect(() => {
    let isMounted = true;

    if (isSuccess) {
      formMethods.reset();

      setTimeout(() => {
        if (isMounted) {
          setIsDialogOpen(false);
        }
      }, 1000);
    }

    return () => {
      isMounted = false;
    };
  }, [isSuccess]);

  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={handleSubmit(debouncedHandleSubmit)}
        data-form="join-houzit"
      >
        <div className={''}>
          <InputContainer>
            <TextInput
              name={'name'}
              placeholder={t('name')}
              validation={{
                required: true,
                maxLength: 50,
                validate: (value: string) => value?.trim()?.length > 1,
                setValueAs: (value: string) => value?.trim()
              }}
            />
            <TextInput
              name={'surname'}
              placeholder={t('surname')}
              validation={{
                required: true,
                maxLength: 50,
                validate: (value: string) => value?.trim()?.length > 1,
                setValueAs: (value: string) => value?.trim()
              }}
            />
          </InputContainer>
          <InputContainer>
            <PhoneInput name={'phone'} placeholder={t('contact-number')} />
          </InputContainer>
          <InputContainer>
            <TextInput
              name={'email'}
              placeholder={t('email-address')}
              validation={{
                required: true,
                minLength: 3,
                maxLength: 100,
                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
              }}
            />
          </InputContainer>
          <InputContainer>
            <TextArea
              placeholder={t('additional-comments')}
              rows={3}
              name="message"
            />
          </InputContainer>
          <PrivacyPolicyCheckbox />
        </div>
        <div className={'tw-w-fit tw-mx-auto tw-my-5'}>
          <Button
            variant="primary"
            disabled={isSubmitting || isSending || isSuccess}
            label={t('send')}
            className={clsx(
              isError && '!tw-bg-red-600',
              isSuccess && 'tw-pointer-events-none'
            )}
          />
        </div>
      </form>
    </FormProvider>
  );
}
