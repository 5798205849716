import { useTranslation } from 'next-i18next';
import React, { useState } from 'react';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import { getAlternateRoutes } from '../../../../lib/i18n/getTranslateRoutes';
import { DEFAULT_LANG, languages } from '../../../const/language.const';

import ChevronDownSVG from '../../../../public/assets/icons/chevron_down.svg';

export const LanguageSelector = ({ showOnMobile = false }) => {
  const { asPath, locale = DEFAULT_LANG, push: changeUrl } = useRouter();
  const {
    i18n: { language, changeLanguage }
  } = useTranslation(['common']);
  const [isOpen, setIsOpen] = useState(false);
  const lng = languages.find(lang => lang.value === locale);
  const changeWidth = isOpen && language !== 'pt';

  return (
    <div
      className={clsx(
        'tw-z-50 tw-relative tw-group tw-border tw-border-transparent tw-duration-300 hover:tw-border-white tw-rounded-md md:tw-block',
        isOpen && 'tw-bg-white tw-shadow-md',
        !showOnMobile && 'tw-hidden'
      )}
    >
      {isOpen && (
        <div
          className={'tw-fixed tw-inset-0 tw-cursor-pointer'}
          onClick={() => setIsOpen(false)}
        />
      )}
      <button
        className={clsx(
          'tw-flex tw-gap-2 tw-items-center tw-py-2 tw-px-3 tw-w-[92px] tw-duration-300 tw-min-w-[92px]',
          isOpen ? 'tw-text-defaultColor' : 'tw-text-white',
          changeWidth && '!tw-min-w-[115px]'
        )}
        onClick={() => setIsOpen(prev => !prev)}
      >
        <img
          src={lng?.path}
          alt={lng?.text}
          className="tw-w-[15px] tw-rounded-[1px]"
        />
        <span className="tw-font-medium">{lng?.short}</span>
        <ChevronDownSVG
          className={clsx(
            'tw-ml-2 tw-opacity-0 group-hover:tw-opacity-100 tw-duration-300',
            isOpen && 'tw-rotate-180 tw-opacity-100',
            changeWidth && 'tw-translate-x-[18px]'
          )}
        />
      </button>
      <div
        className={clsx(
          'tw-absolute tw-top-[calc(100%+2px)] -tw-left-[1px] tw-border tw-rounded-md tw-w-[calc(100%_+_2px)] tw-duration-300 tw-transition-all tw-grid',
          isOpen
            ? 'tw-grid-rows-[1fr] tw-shadow-md tw-translate-y-[-10px] tw-border-white tw-bg-white tw-opacity-100'
            : 'tw-grid-rows-[0fr] tw-pointer-events-none tw-border-transparent tw-opacity-0'
        )}
      >
        <div className="tw-overflow-hidden">
          <div className="tw-pt-2 tw-pb-1">
            {languages.map(
              (el, index) =>
                el.value !== locale && (
                  <button
                    key={index}
                    className={clsx(
                      'tw-flex tw-w-full tw-text-caption tw-gap-2 tw-items-center tw-py-2 tw-px-3',
                      isOpen ? 'tw-text-defaultColor' : 'tw-text-white'
                    )}
                    onClick={() => {
                      const newLng = el?.value;
                      const newUrl = getAlternateRoutes(
                        lng?.value,
                        newLng,
                        asPath
                      ).replace(/#.*$/, '');

                      localStorage.setItem('user_lng', newLng);
                      document.cookie = `NEXT_LOCALE=${newLng}; path=/; expires=Fri, 31 Dec 9999 23:59:59 GMT`;

                      changeLanguage(newLng);
                      changeUrl(newUrl, newUrl, { locale: newLng });
                      setIsOpen(false);
                    }}
                  >
                    <img
                      src={el?.path}
                      alt={el?.text}
                      className="tw-w-[15px] tw-rounded-[1px]"
                      loading="lazy"
                    />
                    <span>{el.text}</span>
                  </button>
                )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
